import config from "#app/env";
import { resetTimer } from "#app/lib/activityTimer";
import { Button } from "#ui/button";
import { Icon } from "#ui/icon";
import { cx } from "#ui/style.utils";
import { useRouter } from "next/navigation";
import { useCountdown } from "./Countdown";
import { activityTimerName } from "./InactivityTimer";

export function AutoLogoffBanner() {
  const router = useRouter();
  const timeRemaining = useCountdown({
    onCountdownComplete: () => router.push("/sign-out?sessionExpired=true"),
    countdownTime: config.autoLogOffCountdownTime,
  });

  return (
    <div
      role="status"
      className={cx(
        "grid grid-cols-[auto,1fr] items-center gap-5px",
        "px-20px py-10px md:px-30px",
        "bg-warning-20 text-shade-100",
        "text-sm",
      )}
    >
      <Icon name="ms-warning-filled" palette="warning" />
      <span className="sr-only">Warning</span>
      <span>
        Your Baird Online session will expire in{" "}
        <span className="font-semibold">{timeRemaining}</span> seconds.{" "}
        <Button
          onClick={() => resetTimer(activityTimerName, {})}
          variant="link"
          underline
        >
          Click here to continue using Baird Online.
        </Button>
      </span>
    </div>
  );
}
