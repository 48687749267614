"use client";

import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import ErrorFallback from "#app/_ui/components/errors/error-fallback";
import {
    PageMessageAlert,
    usePageMessage,
} from "#app/_ui/components/errors/page-messages";
import { AuthorizedFooter } from "#app/_ui/components/footers/AuthorizedFooter";
import { AutoLogoff } from "#app/_ui/components/headers/autoLogoff/AutoLogoff";
import { Header } from "#app/_ui/components/headers/header";
import { IcSessionIframe } from "#app/_ui/components/ic/IcIFrameSessionManager";
import AppProgressPending from "#app/_ui/components/pages/AppProgressPending";
import { licenseKey } from "#app/devextreme-license";
import { DynamicFactSetLoader } from "#app/DynamicFactSetLoader";
import { logTrace } from "#app/lib/logger";
import { buildRedirectUrl } from "#app/lib/searchParamsUtil";
import { LandmarkLink } from "#ui/landmark-link";
import { useIsRestoring } from "@tanstack/react-query";
import devExConfig from "devextreme/core/config";
import { redirect, usePathname } from "next/navigation";
import { ErrorBoundary } from "react-error-boundary";

export default function SecuredLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  devExConfig({ licenseKey });
  const isRestoring = useIsRestoring();
  const jwt = useJwt();
  const pathname = usePathname();
  const pageMessage = usePageMessage({ path: pathname });

  if (jwt.segment == Segment.NotLoggedIn) {
    logTrace("Unauthenticated, redirecting...");
    return redirect(buildRedirectUrl());
  }

  if (isRestoring) {
    return null;
  }

  if (jwt.requiredActivities.length > 0) {
    return <AppProgressPending />;
  }

  if (
    pageMessage &&
    pageMessage.isAvailable === false
  ) {
    return redirect("/maintenance");
  }

  return (
    <>
      <DynamicFactSetLoader />
      <IcSessionIframe />
      <AutoLogoff />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!!pageMessage && pageMessage.isAvailable === true ? (
          <PageMessageAlert>{pageMessage.statusMessage}</PageMessageAlert>
        ) : null}
        <LandmarkLink type="trigger" slug="main" label="main content" />
        <LandmarkLink type="trigger" slug="footer" label="footer content" />
        {pathname.startsWith("/sharing") ? null : <Header />}
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <LandmarkLink type="target" slug="main" label="main content" />
          {children}
        </ErrorBoundary>
        <LandmarkLink type="target" slug="footer" label="footer content" />
        <AuthorizedFooter />
      </ErrorBoundary>
    </>
  );
}
